import { useSearchParams } from 'next/navigation'

const errorMessages = {
  gre: 'We were unable to verify your browser, please try again.',
  csrf: 'We were unable to verify your request, please try again.',
  email: 'Please enter your email address.',
  password: 'Please enter your password.',
  incorrect: 'Your login details were incorrect, please try again.',
  pwned:
    'Your password has appeared in public data breaches from other websites so is not secure, please enter a new one.',
  pw_nomatch: 'Passwords do not match',
  terms:
    'Please confirm that you accept the Privacy policy and Terms & Conditions',
  already_registered: 'You are already registered, please log in',
}

export const useErrorMessage = searchParams => {
  const errorCode =
    typeof searchParams.get === 'function'
      ? searchParams.get('error_code')
      : searchParams['error_code']

  if (errorCode === 's') {
    return typeof searchParams.get === 'function'
      ? searchParams.get('error_msg')
      : searchParams['error_msg']
  }

  return errorCode && errorCode in errorMessages
    ? errorMessages[errorCode]
    : undefined
}

'use client'
import { useState, useEffect, useRef } from 'react'

export const TokenizedForm = ({
  actionName,
  children,
  ...formProps
}: {
  actionName: string
  children: React.ReactNode
} & React.FormHTMLAttributes<HTMLFormElement>) => {
  const formRef = useRef<HTMLFormElement | undefined>()
  const inputRef = useRef<HTMLInputElement | undefined>()

  const [form, setForm] = useState<{
    rltoken?: string
    wantSubmit?: boolean
  }>({})

  useEffect(() => {
    if (
      form.wantSubmit &&
      formRef.current &&
      inputRef.current &&
      form.rltoken &&
      form.rltoken === inputRef.current.value
    ) {
      setForm({ ...form, wantSubmit: false })
      setTimeout(
        () => (formRef.current ? formRef.current.submit() : undefined),
        50
      )
    }
  }, [
    !!form.wantSubmit,
    formRef.current,
    inputRef.current,
    inputRef.current?.value,
    form.rltoken,
  ])

  const handleSubmit = e => {
    if (!form.rltoken) {
      e.preventDefault()
      fetch('/api/auth/rltoken', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ actionName }),
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          return response.json().then(x => {
            if (x.redirect) document.location.href = x.redirect
            throw new Error(x.error)
          })
        })
        .then(data =>
          setForm({ ...form, rltoken: data.value, wantSubmit: true })
        )
        .catch(e => {
          console.error(e)
          setForm({ ...form, rltoken: '', wantSubmit: false })
        })
      return false
    } else if (formProps.onSubmit) {
      formProps.onSubmit(e)
    }
  }

  return (
    <>
      <form {...formProps} ref={formRef} onSubmit={handleSubmit}>
        {children}
        <input
          ref={inputRef}
          type="hidden"
          name="rltoken"
          id="rltoken-input"
          value={form.rltoken ?? ''}
        />
      </form>
    </>
  )
}

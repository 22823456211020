'use client'
import Link from 'next/link'
import { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'next/navigation'
import { TokenizedForm } from './TokenizedForm'
import { useErrorMessage } from './useErrorMessage'
import { hasAutofill } from '@/lib/dom'

export const LoginForm = ({ email }: { email?: string }) => {
  const searchParams = useSearchParams()

  const emailRef = useRef<HTMLInputElement | undefined>()
  const passwordRef = useRef<HTMLInputElement | undefined>()

  const [form, setForm] = useState<{
    email?: string
    hasPassword?: boolean
    showPassword?: boolean
  }>({ email })

  const errorMessage = useErrorMessage(searchParams)

  useEffect(() => {
    const timer = setInterval(pollForUpdates, 1000)
    pollForUpdates()
    return () => clearInterval(timer)
  }, [])

  const handleChange = e =>
    e.target.name === 'password'
      ? setForm({ ...form, hasPassword: e.target.value.length > 0 })
      : setForm({
          ...form,
          [e.target.name === 'username' ? 'email' : e.target.name]:
            e.target.value,
        })

  const pollForUpdates = () => {
    if (!emailRef.current || !passwordRef.current) {
      return
    }
    if (emailRef.current.value?.length && !form.email?.length) {
      setForm({
        ...form,
        email: emailRef.current.value,
        hasPassword: passwordRef.current?.value?.length > 0,
      })
    } else if (
      hasAutofill(emailRef.current) &&
      hasAutofill(passwordRef.current)
    ) {
      setForm({ ...form, email: 'example@example.com', hasPassword: true })
    } else {
      setForm({ ...form, hasPassword: passwordRef.current?.value?.length > 0 })
    }
  }

  const valid =
    form.email?.length > 0 &&
    /^.+@.+\..{2,}/.test(form.email) &&
    form.hasPassword

  return (
    <TokenizedForm
      actionName="login"
      className="tw-mx-auto tw-w-full md:tw-w-1/2 large auth-form fs-unmask"
      encType="multipart/form-data"
      method="post"
      action="/auth/login"
    >
      {errorMessage ? <p className="form__error">{errorMessage}</p> : null}
      <fieldset>
        <legend className="accessibility">Login</legend>
        <div className="field field--email">
          <label htmlFor="auth-email">
            <span>Email address:</span>
          </label>
          <input
            ref={emailRef}
            autoFocus={!email}
            defaultValue={email}
            name="username"
            id="auth-email"
            type="email"
            autoComplete="email"
            placeholder="Email"
            onChange={handleChange}
          />
        </div>
        <div className="field field--password">
          <label htmlFor="auth-password">
            <span>Password:</span>
          </label>
          <input
            ref={passwordRef}
            autoFocus={!!email}
            name="password"
            id="auth-password"
            type="password"
            autoComplete="current-password"
            placeholder="Password"
            onChange={handleChange}
          />
          <Link href="/login/reset" className="tw-float-right">
            Recover password
          </Link>
        </div>
      </fieldset>
      <div className="footer">
        <input
          type="hidden"
          name="referer"
          value={searchParams.get('referer') ?? searchParams.get('next') ?? ''}
        />
        <button
          type="submit"
          className="btn btn--block btn--primary btn--continue tw-text-2xl tw-font-bold"
          disabled={!valid}
        >
          Sign In{' '}
          <img
            className="continue-arrow"
            src="/static/images/continue-button-circle-arrow.svg"
            width={45}
            height={45}
            alt=""
          />
        </button>
      </div>
    </TokenizedForm>
  )
}
